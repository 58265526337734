import React from 'react';
import ContactForm from './ContactForm';
import Icon from './Icon'; // Import the Icon component

const Contact = () => {
  const divStyle = {
    marginTop: '150px',
  };

  return (
    <div className="contact-container" style={divStyle}>
      <h1>Contact Us</h1>
      <p>If you have any questions or inquiries, please feel free to contact us.</p>
      <div className="contact-info">
        <p>Email: contact@example.com</p>
        <p>  Phone: <a href="tel:99710 48209" > +91 99710 48209 </a></p>
        <p>  Phone: <a href="tel:99272 58209" > +91 99272 58209 </a></p>
        <p>Address 1: HC Lohani, House No. 1, Darmoli, Mukteshwar, Nainital, Uttarakhand</p>
        <p>Address 2: Awas Vikas, Near Petrol Pump, Nainital Road, Haldwani, Uttarakhand</p>
      </div>
      <ContactForm />
      <Icon /> {/* Include the Icon component here */}
    </div>
  );
};

export default Contact;
