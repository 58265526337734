import React from 'react';

const Footer = () => {
  return (
    <footer>
     <div className="container" style={{ backgroundColor: '#FF7A1B' , marginTop: '0px' }}>
        <div className="row">
          <div className="col-md-6">
            <h3>Contact Us</h3>
            <p>Address- HC Lohani, house no 1 Darmoli, Mukteshwar, Nainital, Uttarakhand</p>
            <p>2nd Address- Awas Vikas near petrol pump nainital road, Haldwani, Uttarakhand</p>
            <p>Email: <a href="mailto:info@trinetrabuilders.com">info@trinetrabuilders.com</a></p>

            <p>  Phone: <a href="tel:99710 48209" > +91 99710 48209 </a></p>
            <p>  Phone: <a href="tel:99272 58209" > +91 99272 58209 </a></p>

          </div>
          <div className="col-md-3">
            <h3>Quick Links</h3>
            
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/about">About Us</a>
              </li>
              <li>
                <a href="/service">Services</a>
              </li>
              <li>
                <a href="/project">Projects</a>
              </li>
              <li>
                <a href="/contact">Contact</a>
              </li>
            
          </div>
          <div className="col-md-3">
            <h3>Follow Us</h3>
            <ul className="list-unstyled">
              <li>
                <a href="https://www.facebook.com/profile.php?id=61550823131821">
                  <i className="fab fa-facebook"></i> Facebook
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fab fa-twitter"></i> Twitter
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fab fa-linkedin"></i> LinkedIn
                </a>
              </li>
              <li>
                <a href="https://instagram.com/trinetracontractorsbuilders?utm_source=qr&igshid=OGU0MmVlOWVjOQ==">
                  <i className="fab fa-instagram"></i> Instagram
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p>&copy;Copyright 2023 Trinetra Builders Construction Company .</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

