import React from 'react';

const Team = () => {
  const teamMembers = [
    {
      name: 'Trinetra Kumar',
      
      image: `${process.env.PUBLIC_URL}/image/Team1.jpg`,
      description: 'Managing Director (10 plus years of experience in construction)',
    },
    {
      name: 'Pawan Lohani',
      
      image: `${process.env.PUBLIC_URL}/image/Team2.jpg`,
      description: 'Director (15 plus years of experience in construction)',
    },
    {
        name: 'Kamal Joshi',
        
        image: `${process.env.PUBLIC_URL}/image/Team3.jpg`,
        description: 'Senior Engineer (10 plus years of experience in Civil Engineering & Construction)',
      },
  ];

  return (
    <div className="container text-center">
      <h1 className="mt-5">Our Team</h1>
      <div className="row">
        {teamMembers.map((member, index) => (
          <div key={index} className="col-md-4">
            <div className="card mb-4">
              <img src={member.image} alt={member.name} className="card-img-top" />
              <div className="card-body">
                <h5 className="card-title">{member.name}</h5>
               
                <p className="card-text">{member.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Team;
