import React, { useState } from 'react';

const Service = () => {
  const divStyle = {
    marginTop: '150px',
  };

  const services = [
    // Your services data...
    {
        title: 'Building Plan, Approval & 3D Elevation',
        description: 'We provide Building plan, Approval & 3D Elevation services that are dedicated to all types of building construction projects including residential, commercial, industrial buildings. We develop Building plan and 3D models with the higher level of details to make informed decisions and facilitate quick modelling. This is a unique technology that allows clients to visually “walk the halls” and “fly-over” their building design. With 3D Rendering, we can bring the design to life and can easily share unique features of the project or discuss elements like roof pitches or loading dock access in real-time.',
        imagePath: `${process.env.PUBLIC_URL}/image/Card8.jpg`,
    },
    {
        title: 'Residential Development',
        description: 'The Trinetra Group, the most trusted construction company in all Kumaon District in Uttrakhand, we listen to your needs and come up with the perfect plan and solutions that will deliver a fantastic Home for you while respecting your budget. Whether you want to redesign your entire home, renovate or build a new home, you never have to compromise on what you want. We are experienced civil contractors with the technical knowledge and market awareness to meet complex challenges.Our mission is to engage in issues that are of concern to individuals, families and communities through an uncompromising commitment to create outstanding living, work and leisure environments.',
        imagePath: `${process.env.PUBLIC_URL}/image/Card7.jpg`,
    },
    {
        title: 'Commercial Development',
        description: 'Our mission is to provide the best facilities and services to our customers in reducing their pains in doing commercial activities in a Resorts, Hotels, Hospital and Shopping complex. We are gaining consistent appreciation among the clients by rendering Commercial Building Construction Services to the clients  These services are provided by our ingenious professionals by working in close-coordination with clients to construct the building by judicial utilization of given carpet area. With the aid of contemporary techniques and quality-assured construction materials, we also assure the installation of lift, generator, garden and other utility accessories.',
        imagePath: `${process.env.PUBLIC_URL}/image/Card6.jpg`,
    },
    {
        title: 'Government Construction',
        description: 'We are registered government contractor for Canal, PWD, Irrigation etc. Our mission is to engage in issues that are of concern to individuals, families and communities through an uncompromising commitment to create outstanding living, work and leisure environments. ',
        imagePath: `${process.env.PUBLIC_URL}/image/Card5.jpg`,
    },
    {
        title: 'Interior Design',
        description: 'We are most trusted construction company for interior and exterior design. Our experiences that are distinctive, compelling and of superb quality and individuality. Our designers successfully participate in projects from the initial concepts, furniture and decorative item selections, decorative material selections, construction document production, budgeting, project coordination – always with precision, professionalism, attention to detail, exceptional customer service and expert project management skills. As interior design firm, we are ahead of our competitors in terms of selecting real-life products. Since early stages of design, high-quality items are carefully selected according to project budget & location.',
        imagePath: `${process.env.PUBLIC_URL}/image/Card4.jpg`,
    },
    {
        title: 'Modular Kitchen',
        description: 'With the help of our expert professionals, we are providing our customers with the best Kitchen Interior designs and solutions. Modular Kitchens are the request of the day in a time that spotlights on comfort, space economy and utility worth. Our Modular Kitchens oblige these necessity as they are mixes of advanced components set up together into a minimal arrangement. These are customized to suit the client’s necessities regarding their building, applications, strength, utilization and class.',
        imagePath: `${process.env.PUBLIC_URL}/image/Card10.jpg`,
    },
    {
        title: 'Modern Bathroom',
        description: 'We are bringing you bathroom interior designs that are functional and also stylish. Whether you’re looking for small bathroom or spacious bathroom, we are able to create some elegant bathroom designs for your next home or renovation project.',
        imagePath: `${process.env.PUBLIC_URL}/image/Card9.jpg`,
    },
    {
        title: 'Modern Terrace Garden',
        description: 'We are providing best Modern Terrace Garden. Unwind In Style with A Modern Terrace Garden  With a roof terrace garden, your private outdoor escape is just a step away. Talk about unwinding after a long day, watching beautiful sunsets, and celebrating under the stars. This is exactly what a modern terrace garden design can bring to life. A thoughtfully crafted space that embodies your personality, focuses on your lifestyle and goes beyond your imagination.',
        imagePath: `${process.env.PUBLIC_URL}/image/Card11.jpg`,
    },
  ];

  const [expandedServices, setExpandedServices] = useState({});
  
  // Function to toggle expansion for a service
  const toggleExpandedService = (index) => {
    setExpandedServices((prevExpanded) => ({
      ...prevExpanded,
      [index]: !prevExpanded[index]
    }));
  };

  return (
    <div className="container" style={divStyle}>
      <h1 className="text-center mt-5">Services</h1>
      <div className="row">
        {services.map((service, index) => (
          <div key={index} className="col-md-6 mt-4">
            <div className="card">
              <img
                src={service.imagePath}
                className="card-img-top"
                alt={service.title}
                style={{ width: '100%', height: '200px' }}
              />
              <div className="card-body" style={{ backgroundColor: '#EFEFEF' }}>
                <h5 className="card-title">{service.title}</h5>
                {expandedServices[index] ? (
                  <p className="card-text">{service.description}</p>
                ) : (
                  <p className="card-text">
                    {service.description.substring(0, 90)}...
                  </p>
                )}
                <button
                  className="btn btn-primary"
                  onClick={() => toggleExpandedService(index)}
                >
                  {expandedServices[index] ? 'Read Less' : 'Read More'}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Service;
